<template>
	<section class="section">
		<base-container>
			<cms-text
				:value="`${prefix}.title`"
				:props="{variant: 'section-header', tag: 'h2'}"
			/>
			<base-row>
				<base-col col="12" lg="6" xl="5">
					<contact-form />
				</base-col>
				<base-col col="12" lg="6"  xl="7">
					<image-wrapper class="img-wrapper" wider>
						<cms-image
							:value="`${prefix}.aside_img`"
							:md="{width: 690, height: 200}"
							:lg="{width: 930, height: auto}"
							:xl="{width: 830}"
						/>
					</image-wrapper>
				</base-col>
			</base-row>
		</base-container>
	</section>
</template>
<script>
import ContactForm from '~/website/front/components/sections/ContactForm'
import ImageWrapper from '~/website/front/components/atoms/ImageWrapper'

export default {
	components: {
		ImageWrapper,
		ContactForm
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.section {
	min-height: 905px;
}
:deep(.img-wrapper) {
	min-height: 200px;
	@include media-breakpoint-up(lg) {
		min-height: 300px;
	}
	@include media-breakpoint-up(xl){
		min-height: 410px;
	}
}
</style>
