<template>
	<base-blur :blur="!!status">
		<form class="form" v-if="form">
			<input-template wider :input="form.inputs.email" />
			<div class="form-inner-wrapper">
				<input-template wider :input="form.inputs.name" />
				<input-template wider :input="form.inputs.phoneNumber" />
			</div>
			<input-template wider :input="form.inputs.content" />
			<input-template :input="form.inputs.police" />

			<button-submit @click="submit" :loading="loading" type="secondary" class="form-btn" :rightSlot="true">
				<base-font color="white" tag="span">
					{{langs.button}}
				</base-font>
			</button-submit>
			<base-font class="form-disclaimer" size="xs" family="primary" color="primary">
				{{ langs.disclaimer }}
			</base-font>
		</form>
		<template #status>
			<div class="status" >
				<base-font size="md" family="secondary" color="primary">
					{{statusTranslation[status] || statusTranslation.error}}
				</base-font>
			</div>
		</template>
	</base-blur>
</template>
<script>
import ButtonSubmit from '~/website/front/components/molecules/ButtonSubmit'

import { Form } from '@f/core/forms/Form'
import { InputTemplate, InputText, InputEmail, Textarea, InputCheckbox } from '@f/core/forms/inputs'
import { required, email } from 'utils/inputs/validation-front'
export default {
	data () {
		return {
			status: '',
			form: false,
			loading: false
		}
	},
	computed: {
		statusTranslation () {
			return this.langs.status
		},
		langs () {
			return this.$app.translator.get('forms.contactForm')
		}
	},
	components: {
		InputTemplate,
		ButtonSubmit
	},
	async mounted () {
		await this.$app.translator.prefetch('errors')
		await this.$app.translator.prefetch('forms.contactForm')
		this.form = new Form()
		this.form.addInput(InputText.create({
			translations: () => this.$app.translator.get('forms.contactForm.inputName'),
			attribute: 'name',
			name: 'name',
			validation: [required()]
		}))
		this.form.addInput(InputEmail.create({
			translations: () => this.$app.translator.get('forms.contactForm.inputEmail'),
			attribute: 'email',
			name: 'email',
			validation: [email()]
		}))
		this.form.addInput(InputText.create({
			translations: () => this.$app.translator.get('forms.contactForm.inputPhoneNumber'),
			attribute: 'phoneNumber',
			name: 'phoneNumber',
			validation: [required()]
		}))
		this.form.addInput(Textarea.create({
			translations: () => this.$app.translator.get('forms.contactForm.inputTextarea'),
			attribute: 'content',
			name: 'content',
			validation: [required()]
		}))
		this.form.addInput(InputCheckbox.create({
			translations: () => this.$app.translator.get('forms.contactForm.inputPolice'),
			attribute: 'police',
			name: 'police',
			validation: [required()]
		}))
	},
	methods: {
		async submit () {
			if (!await this.form.validate()) return false
			this.loading = true
			const status = await this.$app.getService('rext').sendContactFormMessage(
				this.form.getValues()
			)
			this.loading = false
			this.status = status

			if (status === 201) this.$app._emit('contact_form:message:sent')
		}
	}
}
</script>
<style lang="scss" scoped>
.form {
	min-height: max-content;

	& :deep(label)  {
		color: $primary;
	}
	&-inner-wrapper {
		@include media-breakpoint-up(xxl) {
			display: flex;
			align-items: center;
		}
	}
	.form-btn {
		:deep(.btn) {
			margin-bottom: 0;
			width:auto;
		}
	}
	:deep(.form-disclaimer)  {
		margin: 2rem 0;
	}
}
</style>
